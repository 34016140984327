import 'hammerjs';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { BootController } from './boot';
import { environment } from './environments/environment';
import { getTranslationProviders } from './locale/translation.provider';

if (environment.production) {
  enableProdMode();
}

BootController.instance.reboot.subscribe(() => platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err))
);
