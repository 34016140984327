<div #shadow class="page-bg mat-elevation-z8"></div>

<div id='pages' #pages class="pages">

  <div *ngFor="let pageNumber of pageNumbers"
       class="page"
       [attr.page]="pageNumber"></div>

  <div *ngIf="!touchMode && !comparingPages && !config.options.pageMarkersDefault" class="page-markers">
    <div *ngFor="let pageMarker of pageMarkers"
         class="page-marker-wrapper"
         [class.left]="isPageMarkerOnLeftSide(pageMarker.page)"
         [class.right]="isPageMarkerOnRightSide(pageMarker.page)"
         [class.top]="visiblePages?.includes(pageMarker.page)">
      <div class="page-marker"
           [class.transparent]="transparency && !pageMarker.tabColor"
           [style.backgroundColor]="setPageMarkerBackground(pageMarker.tabColor)"
           (click)="gotoPage(pageMarker.page)">
        <div class="page-marker-shadow mat-elevation-z4"></div>
        <span class="page-marker-label" [style.color]="pageMarker.tabTextColor">{{pageMarker.tabText}}</span>
      </div>
    </div>
  </div>

</div>
