import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogType, Dimensions, Hotspot, LoaderService, PanelType } from '@page2flip/core/common';
import { DynamicComponentLoader } from '@page2flip/dcl';

import { DialogComponent } from '../../base/dialog/dialog.component';
import { SidePanelService } from '../../base/side-panel/side-panel.service';
import { ConfigComponent } from '../../features/dialogs/config/config.component';
import { DownloadComponent } from '../../features/dialogs/download/download.component';
import { FeedbackComponent } from '../../features/dialogs/feedback/feedback.component';
import { HelpComponent } from '../../features/dialogs/help/help.component';
import { HotspotComponent } from '../../features/dialogs/hotspot/hotspot.component';
import { InfoComponent } from '../../features/dialogs/info/info.component';
import { LegalComponent } from '../../features/dialogs/legal/legal.component';
import { NoteComponent } from '../../features/dialogs/note/note.component';
import { PageComparisonComponent } from '../../features/dialogs/page-comparison/page-comparison.component';
import { ShareComponent } from '../../features/dialogs/share/share.component';
import { PageOverviewComponent } from '../../features/side-panels/page-overview/page-overview.component';
import { SearchComponent } from '../../features/side-panels/search/search.component';
import { ShoppingCartComponent } from '../../features/side-panels/shopping-cart/shopping-cart.component';
import { TableOfContentsComponent } from '../../features/side-panels/table-of-contents/table-of-contents.component';
import { WatchListComponent } from '../../features/side-panels/watch-list/watch-list.component';
import { ConfigurationHolder } from './configuration-holder.service';
import { Constants } from './constants.service';
import { PageSelectComponent } from '../../features/side-panels/page-select/page-select.component';
import { PageMarkersComponent } from "../../features/side-panels/page-markers/page-markers.component";

/**
 * Service to lazy load feature components.
 */
@Injectable()
export class FeatureLoader {

  /**
   * Constructor of the service.
   *
   * @param document  DI Token for the Document object.
   * @param config    Service that holds the viewer configuration.
   * @param dcl       Dynamic Component loader for loading the component factories.
   * @param dialog    Service to open Material Design modal dialogs.
   * @param loader    Service to control the loading animation.
   * @param sidePanel Service to control the side panel.
   */
  constructor(@Inject(DOCUMENT) private document: any,
    private config: ConfigurationHolder,
    private dcl: DynamicComponentLoader,
    private dialog: MatDialog,
    private loader: LoaderService,
    private sidePanel: SidePanelService) { }

  private static resize(height: number, width: number, desiredRatio: number): Dimensions {
    const documentRatio = width / height;
    if (documentRatio > desiredRatio) {
      height = height * Constants.hotspotDialogPercentage / 100;
      width = height * desiredRatio;
    } else {
      width = width * Constants.hotspotDialogPercentage / 100;
      height = width / desiredRatio;
    }
    return { width, height };
  }

  /**
   * Delegates the loading of the feature components.
   *
   * @param feature Type of the feature component.
   * @param options Options for the feature component.
   */
  load(feature: PanelType | DialogType, options?: any) {
    this.loader.start();
    switch (feature) {
      // side panel components
      case 'pageOverview': return this.loadSidePanelFeature<PageOverviewComponent>(feature, 'pages');
      case 'bookmarks': return this.loadSidePanelFeature<PageOverviewComponent>('pageOverview', 'bookmarks');
      case 'notes': return this.loadSidePanelFeature<PageOverviewComponent>('pageOverview', 'notes');
      case 'page-select': return this.loadSidePanelFeature<PageSelectComponent>('page-select', options);
      case 'search': return this.loadSidePanelFeature<SearchComponent>(feature);
      case 'shoppingCart': return this.loadSidePanelFeature<ShoppingCartComponent>(feature);
      case 'tableOfContents': return this.loadSidePanelFeature<TableOfContentsComponent>(feature);
      case 'watchList': return this.loadSidePanelFeature<WatchListComponent>(feature);
      case 'pageMarkers': return this.loadSidePanelFeature<PageMarkersComponent>(feature);
      // dialog components
      case 'config': return this.loadDialogFeature<ConfigComponent>(feature);
      case 'download': return this.loadDialogFeature<DownloadComponent>(feature, options);
      case 'feedback': return this.loadDialogFeature<FeedbackComponent>(feature);
      case 'help': return this.loadDialogFeature<HelpComponent>(feature);
      case 'hotspot': return this.loadDialogFeature<HotspotComponent>(feature, options);
      case 'info': return this.loadDialogFeature<InfoComponent>(feature);
      case 'legal': return this.loadDialogFeature<LegalComponent>(feature);
      case 'note': return this.loadDialogFeature<NoteComponent>(feature, options);
      case 'pageComparison': return this.loadDialogFeature<PageComparisonComponent>(feature);
      case 'share': return this.loadDialogFeature<ShareComponent>(feature);
    }
  }

  /**
   * Loads a feature component into the side panel.
   *
   * @param feature Type of the panel.
   * @param options Options for the panel.
   */
  private loadSidePanelFeature<T>(feature: PanelType, options?: any) {
    this.dcl.getComponentFactory<T>('feature-' + feature).subscribe(
      componentFactory => {
        this.sidePanel.hide();
        this.sidePanel.show({ componentFactory, options })
          .subscribe(() => this.document.dispatchEvent((new CustomEvent('featureLoaded', { detail: feature })))) // TODO: add event to docs
          .unsubscribe();
      },
      error => console.warn(error)
    );
  }

  /**
   * Loads a feature component into a Material Design modal dialog.
   *
   * @param feature Type of the dialog.
   * @param options Options for the dialog.
   */
  private loadDialogFeature<T>(feature: DialogType, options?: any) {
    this.dcl.getComponentFactory<T>('feature-' + feature).subscribe(
      componentFactory => {
        const dimensions: Dimensions = feature === 'hotspot' ? this.getHotspotDialogDimensions(options.hotspot) : null;
        this.dialog.closeAll();
        this.dialog.open(DialogComponent, {
          data: { componentFactory, options },
          panelClass: this.config.touchMode ? 'touch' : '',
          width: this.getWidthForHotspot(options, feature),
          height: this.getHeightForHotspot(options, feature)
        }).afterOpened()
          .subscribe(() => this.document.dispatchEvent((new CustomEvent('featureLoaded', { detail: feature })))); // TODO: add event to docs
      },
      error => console.warn(error)
    );
  }

  private getHeightForHotspot(options: any, feature: 'config' | 'download' | 'feedback' | 'help' | 'hotspot' | 'info' | 'legal' | 'note' | 'pageComparison' | 'share') {
    return this.getSizeFor(options, feature, window.innerHeight / 2);
  }

  private getSizeFor(options: any, feature: 'config' | 'download' | 'feedback' | 'help' | 'hotspot' | 'info' | 'legal' | 'note' | 'pageComparison' | 'share', defaultSize: number) {
    if (this.isImage(options)) {
      return '';
    }
    if ((this.isVideo(options) && this.isUrl(options)) || this.isIframe(options)) {
      return '80%';
    }
    if (this.isVideo(options)) {
      return '';
    }
    if (this.config.touchMode) {
      return '100%';
    }
    if (feature === 'hotspot') {
      if (this.isAudio(options)) {
        return 'auto';
      }
      return defaultSize + 'px';
    }
    return '';
  }

  private isAudio(options: any) {
    return options?.hotspot?.action?.type === 'audio';
  }

  private isIframe(options: any) {
    return options?.hotspot?.action?.type === 'iframe';
  }

  private isImage(options: any) {
    return options?.hotspot?.action?.type === 'image';
  }

  private isVideo(options: any) {
    return options?.hotspot?.action?.type === 'video';
  }

  private isUrl(options: any) {
    const target = options?.hotspot?.action?.target;
    return target && isNaN(Number(target.toString()));
  }

  private getWidthForHotspot(options: any, feature: 'config' | 'download' | 'feedback' | 'help' | 'hotspot' | 'info' | 'legal' | 'note' | 'pageComparison' | 'share') {
    return this.getSizeFor(options, feature, window.innerWidth / 3);
  }

  /**
   * Get a hotspots' dialog dimensions corresponding to its type.
   *
   * @param hotspot Hotpot passed to the dialog.
   */
  private getHotspotDialogDimensions(hotspot: Hotspot): Dimensions {
    switch (hotspot.action.type) {
      case 'gallery':
      case 'iframe':
        return hotspot.action.width > this.document.documentElement.clientWidth ||
          hotspot.action.height > this.document.documentElement.clientHeight ?
          this.resizeToRatio(hotspot.action.width / hotspot.action.height) :
          { width: hotspot.action.width, height: hotspot.action.height };

      case 'video':
        return this.resizeToRatio(Constants.hotspotVideoRatio);

      case 'audio':
      case 'image':
      case 'article':
        return null;
    }
  }

  /**
   * Calculates the dimensions of a hotspot depending on Document dimensions and content ratio.
   *
   * @param contentRatio Content ratio of the hotspot.
   */
  private resizeToRatio(contentRatio: number): Dimensions {
    return FeatureLoader.resize(this.document.documentElement.clientHeight, this.document.documentElement.clientWidth, contentRatio);
  }

}
