import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { AnalyticsConfiguration, GoogleAnalyticsEvent } from "@page2flip/core/common";
import {ReplaySubject, Subject} from "rxjs";
import { SpoUrlService } from "./spo-url.service";
import { HttpClient } from "@angular/common/http";
import { StorageMap } from "@ngx-pwa/local-storage";
import { GoogleAnalytics } from "./google-analytics.service";
import { MatomoEvent } from "@page2flip/core/common/src/lib/interfaces/matomo-event.interface";
import { MatomoService } from "./matomo.service";
import { ConfigurationHolder } from "./configuration-holder.service";
import {
  CookieConsentDialogComponent
} from "@page2flip/core/common/src/lib/components/cookie-consent-dialog/cookie-consent-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { matomoConfig } from "../../../config/window-config";

declare let ga: Function | undefined;
declare let gtag: Function | undefined;

@Injectable({
  providedIn: 'root'
})
export class TrackingService{

  readonly apiUrl;
  readonly scopeKey;
  private assetId;
  private documentId: ReplaySubject<string> = new ReplaySubject<string>();
  private isHandset = false;
  private onDestroy = new Subject<void>();
  private baseUrl: string;
  private cookieConsentDialog?: MatDialogRef<CookieConsentDialogComponent>;

  analyticsConfiguration?: AnalyticsConfiguration;

  constructor(
    spoUrlService: SpoUrlService,
    private storage: StorageMap,
    private httpClient: HttpClient,
    private googleAnalyticsService: GoogleAnalytics,
    private config: ConfigurationHolder,
    private matomoService: MatomoService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.scopeKey = spoUrlService.scopeKey;
    this.baseUrl = spoUrlService.backendUrl;
    this.apiUrl = spoUrlService.apiUrl;
    this.assetId = spoUrlService.assetId;
    this.breakpointObserver.observe([
      Breakpoints.Handset
    ])
      .pipe(
        takeUntil(this.onDestroy)
      )
      .subscribe(result => {
        this.isHandset = result.matches;
        if (this.cookieConsentDialog) {
          this.cookieConsentDialog.updateSize(this.isHandset ? '100%' : '40%');
        }
      });

    if (this.scopeKey) {//TODO: Move everything related to the analytics configuration to the file interceptor in space one
      this.httpClient.get<AnalyticsConfiguration[]>(`${this.apiUrl}scope/${this.scopeKey}/items/analyticsConfiguration`)
        .pipe(
          map(analyticsConfigurations => analyticsConfigurations[0]),
          switchMap(analyticsConfiguration => {
            this.analyticsConfiguration = analyticsConfiguration;
            return this.storage.get('cookieConsent');
          })
        )
        .subscribe(cookieConsent => {
          this.manageCreateTracking(cookieConsent);
        });
    }
    try {
      const assetId = Number(spoUrlService.assetId);
      if (assetId && !isNaN(assetId)) {
        this.getAssetContent(assetId)?.subscribe(response => {
            const name = (response as any).name;
            this.documentId.next((response as any).id + (name ? ("-" + name) : ''));
          },
          () => {
            this.documentId.next(window['data'].publication.id);
          });
      } else {
        this.documentId.next(window['data'].publication.id);
      }
    } catch (e) {
      console.error(e);
      this.documentId.next(window['data'].publication.id);
    }
  }

  private getAssetContent(assetId: number) {
    if (!assetId) return null;
    return this.httpClient.get(`${this.apiUrl}scope/${this.scopeKey}/asset/${assetId}/content`);
  }

  private manageCreateTracking(cookieConsent) {
    if (!this.areAnalyticsConfigured()) {
      return;
    }
    if (typeof gtag !== 'undefined') {
      if (cookieConsent === undefined) {
        this.openCookieConsentDialog();
      }
      if (typeof cookieConsent === 'boolean') {
        this.googleAnalyticsService.createGtag(cookieConsent, this.analyticsConfiguration.measurementId);
      }
    } else if (typeof ga !== 'undefined') {
      if (cookieConsent === undefined) {
        this.openCookieConsentDialog();
      }
      if (typeof cookieConsent === 'boolean') {
        this.googleAnalyticsService.createGa(cookieConsent, this.analyticsConfiguration.measurementId)
      }
    }
  }

  private openCookieConsentDialog() {
    this.cookieConsentDialog = this.dialog.open(CookieConsentDialogComponent, {
      closeOnNavigation: false,
      data: {
        analyticsConfiguration: this.analyticsConfiguration
      },
      disableClose: true,
      restoreFocus: false,
      width: '40%'
    });
    this.cookieConsentDialog.afterClosed()
      .pipe(
        switchMap(result => {
          return this.storage.set('cookieConsent', result)
            .pipe(
              map(() => result)
            );
        })
      )
      .subscribe(cookieConsent => {
        this.cookieConsentDialog = undefined;
        this.manageCreateTracking(cookieConsent);
      });
  }

  trackEvent(event: GoogleAnalyticsEvent | MatomoEvent) {
    if (this.config.options.tracking) {
      this.documentId.subscribe(documentId => {
        // If the analytics configuration is null, then the analytics is attempted to be sent before initialization, so we
        // should store it to be sent on the init
        if (!this.analyticsConfiguration || this.areAnalyticsConfigured()) {
          if ((typeof gtag !== 'undefined') && !this.analyticsConfiguration?.measurementId?.startsWith('UA-')) {
            this.googleAnalyticsService.sendGtagEvent(event as GoogleAnalyticsEvent, documentId);
          } else if (typeof ga !== 'undefined') {
            this.googleAnalyticsService.sendGaEvent(event as GoogleAnalyticsEvent, documentId);
          }
        }
        if (matomoConfig) {
          this.matomoService.sendEvent(event as MatomoEvent, documentId);
        }
      });
    }
  }

  private areAnalyticsConfigured() {
    return this.analyticsConfiguration.measurementId && this.analyticsConfiguration.cookieConsentDescription;
  }
}
