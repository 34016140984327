import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@page2flip/core';
import { Article, Note, Storage as Data } from '@page2flip/core/common';
import { BehaviorSubject, Observable } from 'rxjs';

import { getTranslation } from '../../../locale/translation.provider';
import { ConfigurationHolder } from './configuration-holder.service';
import { DocumentService } from './document.service';
import { TrackingService } from "./tracking.service";

/**
 * Service to write user data to browser storage.
 */
@Injectable()
export class Storage {
  private releaseId: string;
  private _bookmarks: number[];
  private _notes: Note[];
  private _shoppingCart: Article[];
  private _watchList: Article[];

  private bookmarksSubject: BehaviorSubject<number[]>;
  private notesSubject: BehaviorSubject<Note[]>;
  private shoppingCartSubject: BehaviorSubject<Article[]>;
  private watchListSubject: BehaviorSubject<Article[]>;

  constructor(
    @Inject(WINDOW) private window: any,
    private config: ConfigurationHolder,
    private doc: DocumentService,
    private trackingService: TrackingService
  ) {
    this.releaseId = doc.publication.id;
    this.load();
  }

  get bookmarks(): Observable<number[]> {
    return this.bookmarksSubject.asObservable();
  }

  get notes(): Observable<Note[]> {
    return this.notesSubject.asObservable();
  }

  get shoppingCart(): Observable<Article[]> {
    return this.shoppingCartSubject.asObservable();
  }

  get watchList(): Observable<Article[]> {
    return this.watchListSubject.asObservable();
  }

  private load() {
    const data: Data =
      this.window.JSON.parse(
        this.window.localStorage.getItem(this.releaseId)
      ) || {};

    this._bookmarks = data.bookmarks ? data.bookmarks : [];
    this._notes = data.notes ? data.notes : [];
    this._shoppingCart = data.shoppingCart ? data.shoppingCart : [];
    this._watchList = data.watchList ? data.watchList : [];

    this.bookmarksSubject = new BehaviorSubject<number[]>(this._bookmarks);
    this.notesSubject = new BehaviorSubject<Note[]>(this._notes);
    this.shoppingCartSubject = new BehaviorSubject<Article[]>(
      this._shoppingCart
    );
    this.watchListSubject = new BehaviorSubject<Article[]>(this._watchList);
  }

  private store() {
    const data = <Data> {
      bookmarks: this._bookmarks,
      notes: this._notes,
      shoppingCart: this._shoppingCart,
      watchList: this._watchList
    };

    this.window.localStorage.setItem(
      this.releaseId,
      this.window.JSON.stringify(data)
    );
  }

  /// bookmarks //////////////////////////////////////////////////////////////////

  toggleBookmark(pageNumber: number) {
    if (this._bookmarks.includes(pageNumber)) {
      this._bookmarks = this._bookmarks.filter(page => page !== pageNumber);
      this.doc.notify(
        getTranslation('bookmarksRemoved')
          ? getTranslation('bookmarksRemoved').replace(
            '{{page}}',
            pageNumber.toString()
          )
          : `Page ${ pageNumber } removed from bookmarks.`
      );
      this.trackingService.trackEvent({
        category: 'bookmark',
        action: 'remove',
        custom1: pageNumber.toString()
      });
    } else {
      this._bookmarks.push(pageNumber);
      this.doc.notify(
        getTranslation('bookmarksAdded')
          ? getTranslation('bookmarksAdded').replace(
            '{{page}}',
            pageNumber.toString()
          )
          : `Page ${ pageNumber } added to bookmarks.`
      );
      this.trackingService.trackEvent({
        category: 'bookmark',
        action: 'add',
        custom1: pageNumber.toString()
      });
    }

    this.store();
    this.bookmarksSubject.next(this._bookmarks);
  }

  isBookmarked(pageNumber: number): boolean {
    return this._bookmarks.includes(pageNumber);
  }

  /// notes //////////////////////////////////////////////////////////////////////

  addNote(note: Note) {
    this._notes = this._notes.filter(n => n.page !== note.page);
    this._notes.push(note);
    this.doc.notify(
      getTranslation('notesSaved')
        ? getTranslation('notesSaved').replace('{{page}}', note.page.toString())
        : `Notes for page ${ note.page } saved.`
    );
    this.trackingService.trackEvent({
      category: 'note',
      action: 'add',
      custom1: note.page.toString()
    });
    this.store();
    this.notesSubject.next(this._notes);
  }

  removeNote(pageNumber: number) {
    this._notes = this._notes.filter(note => note.page !== pageNumber);
    this.doc.notify(
      getTranslation('notesRemoved')
        ? getTranslation('notesRemoved').replace(
          '{{page}}',
          pageNumber.toString()
        )
        : `Notes for page ${ pageNumber } removed.`
    );
    this.trackingService.trackEvent({
      category: 'note',
      action: 'remove',
      custom1: pageNumber.toString()
    });
    this.store();
    this.notesSubject.next(this._notes);
  }

  hasNote(pageNumber: number): boolean {
    return !!this._notes.filter(note => note.page === pageNumber).length;
  }

  getNote(pageNumber: number): string {
    return this._notes.filter(note => note.page === pageNumber).length
      ? this._notes.filter(note => note.page === pageNumber)[ 0 ].text
      : '';
  }

  /// shopping cart //////////////////////////////////////////////////////////////

  updateShoppingCart(shoppingCart: Article[]) {
    this._shoppingCart = shoppingCart;
    this.store();
  }

  addToShoppingCart(article: Article) {
    if (this.alreadyInShoppingCart(article.id)) {
      this.increaseShoppingCartItemQuantity(article.id);
    } else {
      this._shoppingCart.push(article);
      this.trackingService.trackEvent({
        category: 'shoppingCart',
        action: 'add',
        custom2: article.id,
        custom3: article.title
      });
    }

    this.doc.notify(
      getTranslation('shoppingCartAdded')
        ? getTranslation('shoppingCartAdded').replace(
          '{{title}}',
          article.title
        )
        : `${ article.title } added to shopping cart.`
    );
    this.store();
    this.shoppingCartSubject.next(this._shoppingCart);
  }

  removeFromShoppingCart(article: Article) {
    if (this.alreadyInShoppingCart(article.id) && article.quantity > 1) {
      this.decreaseShoppingCartItemQuantity(article.id);
    } else {
      this._shoppingCart = this._shoppingCart.filter(
        item => item.id !== article.id
      );
      this.trackingService.trackEvent({
        category: 'shoppingCart',
        action: 'remove',
        custom2: article.id,
        custom3: article.title
      });
    }

    this.doc.notify(
      getTranslation('shoppingCartRemoved')
        ? getTranslation('shoppingCartRemoved').replace(
          '{{title}}',
          article.title
        )
        : `${ article.title } removed from shopping cart.`
    );
    this.store();
    this.shoppingCartSubject.next(this._shoppingCart);
  }

  clearShoppingCart() {
    this._shoppingCart = [];
    this.doc.notify(
      getTranslation('shoppingCartCleared') || `Shopping cart cleared.`
    );
    this.trackingService.trackEvent({
      category: 'shoppingCart',
      action: 'clear'
    });
    this.store();
    this.shoppingCartSubject.next(this._shoppingCart);
  }

  alreadyInShoppingCart(id: string): boolean {
    return !!this._shoppingCart.filter(item => item.id === id).length;
  }

  increaseShoppingCartItemQuantity(id: string) {
    this._shoppingCart
      .filter(item => item.id === id)
      .map(item => {
        item.quantity++;
        this.trackingService.trackEvent({
          category: 'shoppingCart',
          action: 'increase',
          custom2: item.id,
          custom3: item.title
        });
      });
  }

  decreaseShoppingCartItemQuantity(id: string) {
    this._shoppingCart
      .filter(item => item.id === id)
      .map(item => {
        item.quantity--;
        this.trackingService.trackEvent({
          category: 'shoppingCart',
          action: 'decrease',
          custom2: item.id,
          custom3: item.title
        });
      });
  }

  formatPrice(price: number, addVat: boolean = false): string {
    const formattedPrice: string = price
      ? price.toLocaleString(getTranslation('__localeID') || 'en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      : '0';

    if (addVat) {
      const vatValue: string = this.config.shop.vat
        ? this.config.shop.vat.toString()
        : '';

      const vatExcluded: string = getTranslation('shopVatExcluded')
        ? getTranslation('shopVatExcluded').replace(
          '{{vat}}',
          vatValue && `${ vatValue }%`
        )
        : (vatValue && `${ vatValue }%`) + ' VAT excluded';

      const vatIncluded: string = getTranslation('shopVatIncluded')
        ? getTranslation('shopVatIncluded').replace(
          '{{vat}}',
          vatValue && `${ vatValue }%`
        )
        : (vatValue && `${ vatValue }%`) + ' VAT included';

      const vat: string = this.config.shop.vatIncluded
        ? vatIncluded
        : vatExcluded;

      return `<span class="vat">${ vat }</span> <span class="price">${ formattedPrice } ${ this.config.shop.currency }</span>`;
    }

    return `${ formattedPrice } ${ this.config.shop.currency }`;
  }

  /// watch list /////////////////////////////////////////////////////////////////

  addToWatchList(article: Article) {
    if (this.alreadyInWatchList(article.id)) {
      this.doc.notify(
        getTranslation('watchListExists')
          ? getTranslation('watchListExists').replace(
            '{{title}}',
            article.title
          )
          : `${ article.title } already on watch list.`
      );
      return;
    }

    this._watchList.push(article);
    this.doc.notify(
      getTranslation('watchListAdded')
        ? getTranslation('watchListAdded').replace('{{title}}', article.title)
        : `${ article.title } added to watch list.`
    );
    this.trackingService.trackEvent({
      category: 'watchList',
      action: 'add',
      custom2: article.id,
      custom3: article.title
    });
    this.store();
    this.watchListSubject.next(this._watchList);
  }

  removeFromWatchList(article: Article) {
    this._watchList = this._watchList.filter(item => item.id !== article.id);
    this.doc.notify(
      getTranslation('watchListRemoved')
        ? getTranslation('watchListRemoved').replace('{{title}}', article.title)
        : `${ article.title } removed from watch list.`
    );
    this.trackingService.trackEvent({
      category: 'watchList',
      action: 'remove',
      custom2: article.id,
      custom3: article.title
    });
    this.store();
    this.watchListSubject.next(this._watchList);
  }

  clearWatchList() {
    this._watchList = [];
    this.doc.notify(
      getTranslation('watchListCleared') || `Watch list cleared.`
    );
    this.trackingService.trackEvent({ category: 'watchList', action: 'clear' });
    this.store();
    this.watchListSubject.next(this._watchList);
  }

  alreadyInWatchList(id: string): boolean {
    return !!this._watchList.filter(item => item.id === id).length;
  }
}
