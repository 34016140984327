import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { debounce, Dimensions, ExpandState, Layout, LoaderService, VisibilityState } from '@page2flip/core/common';
import { DynamicComponentLoader } from '@page2flip/dcl';
import { getTranslation } from '../locale/translation.provider';
import { SidePanelService } from './base/side-panel/side-panel.service';
import { DesktopControlsComponent } from './controls/desktop-controls/desktop-controls.component';
import { TouchControlsComponent } from './controls/touch-controls/touch-controls.component';
import { ConfigurationHolder } from './core/services/configuration-holder.service';
import { Constants } from './core/services/constants.service';
import { Designer } from './core/services/designer.service';
import { DocumentService } from './core/services/document.service';
import { EventListener } from './core/services/event-listener.service';
import { ImageLoader } from './core/services/image-loader.service';
import { TranslateService } from "@ngx-translate/core";
import { SpoUrlService } from './core/services/spo-url.service';

@Component({
  selector: 'p2f-viewer',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('sidePanelVisibility', [
      transition(':enter', [
        style({ transform: '{{transform}}', opacity: 0 }),
        animate('250ms ease-in', style({ transform: 'translateX(0)', opacity: 1 })) // FIXME: IE11
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('250ms ease-out', style({ transform: '{{transform}}', opacity: 0 })) // FIXME: IE11
      ])
    ]),
    trigger('sidePanelWidth', [
      transition('collapsed => expanded', [
        style({ width: '520px' }),
        animate('250ms ease-in', style({ width: '100%' }))
      ]),
      transition('expanded => collapsed', [
        style({ width: '100%' }),
        animate('250ms ease-out', style({ width: '520px' }))
      ])
    ])
  ]
})
export class AppComponent implements OnInit {

  @ViewChild('desktopControls', { read: ViewContainerRef }) private desktopControls: ViewContainerRef;
  @ViewChild('touchControls', { read: ViewContainerRef }) private touchControls: ViewContainerRef;

  private element: HTMLDivElement;

  /** Whether or not the user visits from a Mac. */
  readonly isMac: boolean = this.config.isMac;

  debugMode: boolean;
  loading: boolean;
  loaderMessage: string = getTranslation('loading') || 'Loading...';
  statsBottom: string;
  touchMode: boolean;
  visiblePages: number[];
  baseUrl: string;
  scopeKey: string;

  /** Layout configuration. */
  readonly layout: Layout = this.config.layout;

  /** Whether or not transparency is enabled. */
  readonly transparency: boolean = this.config.design.transparency;

  /** Side panel transition. */
  readonly sidePanelTransform = this.config.touchMode ? 'translateY(100%)' : this.config.layout.sidePanelPosition === 'left' ? 'translateX(-100%)' : 'translateX(100%)';

  /** Whether or not the side panel is visible. */
  sidePanelVisible = false;

  /** Whether or not the side panel is expanded. */
  sidePanelWidth: ExpandState = ExpandState.Collapsed;

  constructor(private changeDetector: ChangeDetectorRef,
              private config: ConfigurationHolder,
              private designer: Designer,
              private doc: DocumentService,
              private dcl: DynamicComponentLoader,
              private elementRef: ElementRef,
              private events: EventListener,
              private images: ImageLoader,
              private loader: LoaderService,
              private renderer: Renderer2,
              public translate: TranslateService,
              private sidePanel: SidePanelService,
              spoUrlService: SpoUrlService) {
    events.renderer = renderer;
    this.debugMode = config.debugMode;
    this.touchMode = config.touchMode;
    this.statsBottom = config.layout.toolbarPosition === 'bottom' && !config.layout.hideToolbar ? '70px' : '20px';
    this.element = elementRef.nativeElement;
    this.baseUrl = spoUrlService.backendUrl;
    this.scopeKey = spoUrlService.scopeKey;
    this.setDimensions();
    this.setLanguage();
  }

  ngOnInit() {
    this.loader.loading().subscribe(loading => requestAnimationFrame(() => this.loading = loading));
    this.sidePanel.visibility().subscribe(visibility => this.sidePanelVisible = visibility === VisibilityState.Visible);
    this.sidePanel.width().subscribe(width => this.sidePanelWidth = width);
    this.events.listen();
    this.doc.visiblePages.subscribe(pages => this.visiblePages = pages);
    this.changeDetector.detectChanges();
    this.loadDynamicComponents();
  }


  @HostListener('window:resize')
  @debounce(Constants.resizeEventThreshold)
  onResize() {
    this.doc.resetZoom();
    this.setDimensions();
    this.doc.resize();
  }

  private loadDynamicComponents() {
    if (this.config.touchMode) {
      this.dcl.getComponentFactory<TouchControlsComponent>('touch-controls').subscribe(
        componentFactory => this.touchControls.createComponent(componentFactory),
        error => console.warn(error)
      );
    } else {
      this.dcl.getComponentFactory<DesktopControlsComponent>('desktop-controls').subscribe(
        componentFactory => this.touchControls.createComponent(componentFactory),
        error => console.warn(error)
      );
    }
  }

  private setDimensions() {
    const dimensions: Dimensions = {
      width: this.element.clientWidth,
      height: this.element.clientHeight
    };

    this.doc.setViewerDimensions(dimensions);
    this.doc.setPageDimensions();
    this.doc.setImageQuality();
  }

  setLanguage() {
    const lng = this.translate.getBrowserLang();
    this.translate.setDefaultLang(lng);
    this.translate.use(lng);
    const supportedLangs = ['en', 'de'];
    supportedLangs.forEach((language) => {
      this.translate.reloadLang(language);
    });
  }

  onSwipeLeft() {
    this.doc.nextPage();
  }

  onSwipeRight(){
    this.doc.previousPage();
  }

}
