export const environment = {
  production: true,
  apiUrl: `https://${ location.host }/api`,
  deployUrl: 'https://viewer.groupinnovation-fmd.com/',
  feedbackApiKey: 'mruZFQcdn9ja2rncQ82BatgMTSWEttnn',
  feedbackApiUrl: 'https://portal.page2flip.com/api/scope/stat/items/feedback',
  pdfApiUrl: 'http://pdf-service.page2flip.com/get/index.html',
  spaceOneApiUrl: 'https://page2flip.devdock.space.one/api',
  scopeKey: '_client',
};
